import logoCdtSrc from '../img/logo_cdt.jpg';
import logoBraunschweig from '../img/logo_r-bs.png';
import logoHelmstedt from '../img/logo_helmstedt.png';
import logoGelsenkirchen from '../img/logo_rr_gelsenkirchen.png';
import logoEssen from '../img/logo_rr_essen.png';
import logoWesel from '../img/logo_wesel.jpeg';
import logoEbertplatz from '../img/logo_ebertplatz.png';
import logoDortmund from '../img/logo_rr_gelb.png';
import logoRna from '../img/logo_rna.webp';
import logoStuttgart from '../img/logo_stuttgart.png';

type StringValueOf<T> = T[keyof T] & string

export const LOCATIONS = {
  RADIOS: 'radios',
  RADWML: 'rad-wml',
  STRAHLENINSTITUT: 'strahleninstitut',
  DINKELSBUEHL: 'dinkelsbuehl',
  BRAUNSCHWEIG: 'braunschweig',
  HELMSTEDT: 'helmstedt',
  RUHRRADIOLOGIE_GELSENKIRCHEN: 'ruhrradiologie-gelsenkirchen',
  RUHRRADIOLOGIE_DORTMUND: 'ruhrradiologie-dortmund',
  RUHRRADIOLOGIE_WITTEN: 'ruhrradiologie-witten',
  RUHRRADIOLOGIE_ESSEN: 'ruhrradiologie-essen',
  WESEL: 'wesel',
  EBERTPLATZ: 'ebertplatz',
  RNA: 'rna',
  RH: 'rh-dt.de',
  STUTTGART:'stuttgart'
};

export interface locationEmpty {
  clearName: string,
  email: string,
}

interface location {
  paramName: StringValueOf<typeof LOCATIONS>
  clearName: string,
  link: string,
  email: string,
  logo: string,
  tel: {
    link: string,
    text: string
  }
  options?: {
    customHeaderColor: string,
  }

}


export const stuttgart:location={
  paramName:LOCATIONS.STUTTGART,
  clearName:'Radiologie Stuttgart',
  email:'info@radiologie-stuttgart.de',
  tel:{
    link:'071122550055',
    text:'0711 22 55 00 55'
  },
  link:'https://www.radiologie-stuttgart.de/',
  logo:logoStuttgart
}
export const rna: location = {
  paramName: LOCATIONS.RNA,
  clearName: 'Radiologie Nuklearmedizin Adickesallee',
  email: 'info@radiologie-adickesallee.de',
  tel: {
    link: '0699596160',
    text: '069 95 96 16 0',
  },
  logo: logoRna,
  link: 'https://www.radiologie-nuklearmedizin-adickesallee.de/',
};

export const ebertplatz: location = {
  paramName: LOCATIONS.EBERTPLATZ,
  clearName: 'Köln: MVZ für Radiologie und Nuklearmedizin am Ebertplatz',
  email: 'servive@radiologie-ebertplatz.de',
  logo: logoEbertplatz,
  link: 'https://www.radiologie-ebertplatz.de/',
  tel: {
    link: '02219138880',
    text: '0221 913 888 0',
  },
};

export const wesel: location = {
  paramName: LOCATIONS.WESEL,
  clearName: 'Wesel: MVZ Radiologie im Forum Praxis für Radiologie und' +
      ' Nuklearmedizin GmbH',
  email: 'info@radiologie-wesel.de',
  link: 'https://radiologie-wesel.de/',
  logo: logoWesel,
  tel: {
    link: '028229654610',
    text: '02822 - 96 546 10',
  },
};

export const ruhrradiologieDortmund: location = {
  paramName: LOCATIONS.RUHRRADIOLOGIE_DORTMUND,
  clearName: 'Dortmund: Ruhrradiologie Dortmund',
  email: 'info-dortmund@ruhrradiologie.de',
  link: 'https://www.ruhrradiologie.de/standorte/dortmund',
  logo: logoDortmund,
  tel: {
    text: '0231 6188-304',
    link: '02316188304',
  },
};
export const ruhrradiologieWitten: location = {
  paramName: LOCATIONS.RUHRRADIOLOGIE_DORTMUND,
  clearName: 'Witten: Ruhrradiologie Witten',
  email: 'info@ruhrradiologie.de',
  link: 'https://www.ruhrradiologie.de/standorte/dortmund',
  logo: logoDortmund,
  tel: {
    text: '02302 9360-40',
    link: '02302936040',
  },
};

export const ruhrradiologieEssen: location = {
  paramName: LOCATIONS.RUHRRADIOLOGIE_ESSEN,
  clearName: 'Essen: Ruhrradiologie Essen Rü191',
  email: 'info-rue191@ruhrradiologie.de',
  link: 'https://www.ruhrradiologie.de/standorte',
  logo: logoEssen,
  tel: {
    link: '02017998690',
    text: '0201 - 79 98 690',
  },
};

export const ruhrradiologieGelsenkirchen: location = {
  paramName: LOCATIONS.RUHRRADIOLOGIE_GELSENKIRCHEN,
  clearName: 'Gelsenkirchen: Ruhrradiologie Gelsenkirchen',
  email: 'info@radiologie-lorenz-triebe.de',
  link: 'https://www.ruhrradiologie.de/standorte',
  logo: logoGelsenkirchen,
  tel: {
    link: '02097026440',
    text: '0209 - 70 26 440',
  },
};

export const helmstedt: location = {
  paramName: LOCATIONS.HELMSTEDT,
  clearName: 'Helmstedt: Radiologie Helmstedt',
  link: 'https://www.radiologie-helmstedt.de/',
  email: 'info@radiologie-helmstedt.de',
  logo: logoHelmstedt,
  tel: {
    text: '05351 – 42 011',
    link: '0535142011',
  },
};

export const braunschweig: location = {
  paramName: LOCATIONS.BRAUNSCHWEIG,
  clearName: 'Braunschweig: MVZ Radiologie Braunschweig',
  link: 'https://www.radiologie-bs.de/',
  email: 'info@radiologie-bs.de',
  logo: logoBraunschweig,
  tel: {
    link: '05312421615',
    text: '0531 - 24 21 615',
  },
};

export const strahleninstitut: location = {
  paramName: LOCATIONS.STRAHLENINSTITUT,
  clearName: 'Köln: MVZ CDT Strahleninstitut GmbH',
  link: 'https://strahleninstitut.de/',
  email: 'info@strahleninstitut.de',
  logo: logoCdtSrc,
  tel: {
    link: '022199502100',
    text: '0221 - 99 502 100',
  },
};

export const dinkelsbuehl: location = {
  paramName: LOCATIONS.DINKELSBUEHL,
  clearName: 'Dinkelsbühl: Radiologie Dinkelsbühl',
  link: 'https://radiologie-dinkelsbuehl.de/home.html',
  email: 'info@radiologie-dinkelsbuehl.de',
  logo: 'https://radiologie-dinkelsbuehl.de/assets/images/0/logo_radiologie_dinkelsbuehl-44004ea0.png',
  tel: {
    link: '0985157260',
    text: '0 98 51 - 57 26 0',
  },
  options: {
    customHeaderColor: '#e5e5e5',
  },
};

export const radios: location = {
  paramName: LOCATIONS.RADIOS,
  clearName: 'Düsseldorf: MVZ Radios',
  link: 'https://www.radios-praxen.de',
  email: 'info@radios-praxen.de',
  logo: 'https://www.radios-praxen.de/typo3conf/ext/radios/Resources/Public/Images/radios-logo.png',
  tel: {
    link: '021144773000',
    text: '0211 - 44 77 - 3000',
  },
};

export const radwml: location = {
  paramName: LOCATIONS.RADWML,
  clearName: 'MVZ Rad-WML',
  link: 'https://www.radiologie-westmuensterland.de',
  email: 'info@radiologie-westmuensterland.de',
  logo: 'https://www.radiologie-westmuensterland.de/fileadmin/radiologie-ahaus-vreden-bocholt/Logos/Logo_neu_WML_AH-Bo.png',
  tel: {
    link: '0287129040',
    text: '02871 - 290 40',
  },
};

export const locations = [
  radios,
  radwml,
  dinkelsbuehl,
  strahleninstitut,
  braunschweig,
  helmstedt,
  wesel,
  ebertplatz,
  ruhrradiologieEssen,
  ruhrradiologieGelsenkirchen,
  ruhrradiologieDortmund,
  ruhrradiologieWitten,
];

export const getLocation = (): string | null => {
  const params = getParams();
  return params.get('location');
};

export const getParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params;
};

export const getLocationData = (location: String) => {
  switch (location) {
    case LOCATIONS.STUTTGART:
        return stuttgart
    case LOCATIONS.RADIOS: {
      return radios;
    }
    case LOCATIONS.RADWML: {
      return radwml;
    }
    case LOCATIONS.STRAHLENINSTITUT: {
      return strahleninstitut;
    }
    case LOCATIONS.DINKELSBUEHL: {
      return dinkelsbuehl;
    }
    case LOCATIONS.BRAUNSCHWEIG: {
      return braunschweig;
    }
    case LOCATIONS.HELMSTEDT: {
      return helmstedt;
    }
    case LOCATIONS.RUHRRADIOLOGIE_GELSENKIRCHEN: {
      return ruhrradiologieGelsenkirchen;
    }
    case LOCATIONS.RUHRRADIOLOGIE_ESSEN: {
      return ruhrradiologieEssen;
    }
    case LOCATIONS.WESEL:
      return wesel;
    case LOCATIONS.RUHRRADIOLOGIE_DORTMUND:
      return ruhrradiologieDortmund;
    case LOCATIONS.RUHRRADIOLOGIE_WITTEN:
      return ruhrradiologieWitten;
    case LOCATIONS.EBERTPLATZ:
      return ebertplatz;
    case LOCATIONS.RNA:
      return rna;

    default:
      return false;
  }
};


