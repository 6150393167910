import {Typography, Button, Container} from '@mui/material';

const Datenschutz = () => {
  return (<Container sx={{my: 3, 'p,h2,h3': {mb: 3}}}>
        <Button href={'/'} sx={{mb: 3}} variant={'contained'}>Zurück zur
          Startseite</Button>
        <Typography variant={'h2'} component={'h1'} sx={{mb: 6}}>
          Datenschutzerklärung für die Website
        </Typography>
        <Typography variant={'h5'} component={'h2'}>
          1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
          sowie des betrieblichen Datenschutzbeauftragten
        </Typography>

        <Typography variant={'body1'}>
          Diese Datenschutz-Information gilt für die Datenverarbeitung
          durch: <br/>

          Verantwortlicher:
          <br/> RH Diagnostik & Radiologie GmbH <br/>
          Pettenkofener Str. 22a <br/>
          80336 München
          <br/><br/>
          Telefon 089 23 08 90 88
          <br/><br/>
          Der betriebliche Datenschutzbeauftragte der RH Diagnostik & Radiologie
          GmbH ist unter der o.g. Anschrift, zu Hd. Datenschutzbeauftragter
          (persönlich/vertraulich), beziehungsweise per Email unter
          datenschutzbeauftragter(at)rh-dt.de erreichbar.
        </Typography>

        <Typography variant={'h5'} component={'h2'}>
          2. Erhebung und Speicherung personenbezogener Daten sowie Art und
          Zweck
          von deren Verwendung
        </Typography>

        <Typography variant={'h6'} component={'h3'}> 2 a) Beim Besuch der
          Website</Typography>

        <Typography variant={'body1'}>
          Beim Aufrufen unserer Website www.rh-dt.de werden durch den auf Ihrem
          Endgerät zum Einsatz kommenden Browser automatisch Informationen an
          den
          Server unserer Website gesendet. Diese Informationen werden temporär
          in
          einem sog. Logfile gespeichert. Folgende Informationen werden dabei
          ohne
          Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
          <br/><br/>
          <ul>
            <li>IP-Adresse des anfragenden Rechners,</li>
            <li>Datum und Uhrzeit des Zugriffs,</li>
            <li>Name und URL der abgerufenen Datei,</li>
            <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
            <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners
              sowie der Name Ihres Access-Providers.
            </li>
          </ul>

          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
          <br/><br/>
          Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
          Gewährleistung einer komfortablen Nutzung unserer Website,
          Auswertung der Systemsicherheit und -stabilität sowie
          zu weiteren administrativen Zwecken.
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
          lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse
          folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall
          verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre
          Person zu ziehen.
        </Typography>


        <Typography variant={'h6'} component={'h3'}> 2 b) Bei Nutzung unseres
          Kontaktformulars</Typography>

        <Typography variant={'body1'}>
          Wir bieten Ihnen die Möglichkeit, mit uns über ein auf der Website
          bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe
          der
          Telefonnummer und einer gültigen E-Mail-Adresse erforderlich, damit
          diese beantworten zu können. Weitere Angaben können freiwillig
          getätigt
          werden.
          <br/><br/>
          Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
          nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
          erteilten Einwilligung. Erfolgt die Kontaktaufnahme zur Durchführung
          vorvertraglicher Maßnahmen (z.B. Informationsgespräch) erfolgt die
          Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b DSGVO.
          <br/><br/>
          Die für die Benutzung des Kontaktformulars von uns erhobenen
          personenbezogenen Daten bewahren wir solange auf, wie dies für die
          Durchführung der Behandlung und nach rechtlichen Vorgaben erforderlich
          ist. Aufgrund rechtlicher Vorgaben sind wir dazu verpflichtet, diese
          Daten mindestens 10 Jahre nach Abschluss der Behandlung aufzubewahren.
          Nach anderen Vorschriften können sich längere Aufbewahrungsfristen
          ergeben.
        </Typography>
        <Typography variant={'h5'} component={'h2'}>
          3. Weitergabe von Daten
        </Typography>

        <Typography variant={'body1'}>
          Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
          den
          im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre
          persönlichen Daten nur an Dritte weiter, wenn:
          <br/><br/>
          Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
          Einwilligung dazu erteilt haben,
          die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
          Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und
          kein Grund zur Annahme besteht, dass Sie ein überwiegendes
          schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
          für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
          DSGVO eine gesetzliche Verpflichtung besteht, sowie
          dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für
          die Abwicklung von Vertrags- oder Behandlungsverhältnissen mit Ihnen
          erforderlich ist.
        </Typography>


        <Typography variant={'h5'} component={'h2'}>
          4. Ihre Rechte als von der Datenverarbeitung betroffene Person
        </Typography>

        <Typography variant={'body1'}>
          Sie haben das Recht:
          <br/><br/>
          gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten,
          die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
          wurden oder werden, die geplante Speicherdauer, das Bestehen eines
          Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
          Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer
          Daten, sofern diese nicht bei uns erhoben wurden, sowie über das
          Bestehen einer automatisierten Entscheidungsfindung einschließlich
          Profiling und ggf. aussagekräftigen Informationen zu deren
          Einzelheiten
          verlangen;
          gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu
          verlangen;
          gemäß Art.17 DSGVO die Löschung Ihrer bei Ausübung des Rechts auf
          freie
          Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
          Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          erforderlich ist;
          gemäß Art.18 DSGVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch
          gegen die Verarbeitung eingelegt haben;
          gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen;
          gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft
          nicht mehr fortführen dürfen und
          gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
          der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
          üblichen
          Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes
          wenden.
        </Typography>


        <Typography variant={'h5'} component={'h2'}>
          5. Widerspruchsrecht
        </Typography>

        <Typography>
          Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
          haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
          Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
          sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
          haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
          besonderen Situation von uns umgesetzt wird.
          <br/><br/>
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen,
          genügt eine E-Mail an datenschutzbeauftragter(at)rh-dt.de
        </Typography>


        <Typography variant={'h5'} component={'h2'}>
          6. Datensicherheit / E-Mail-Kommunikation
        </Typography>

        <Typography>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
          wird.
          In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung.
          Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen
          wir
          stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite
          unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
          an der geschlossenen Darstellung des Schüssel- beziehungsweise
          Schloss-Symbols im URL-Feld Ihres Browsers.
          <br/><br/>
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer
          Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche
          Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder
          gegen den unbefugten Zugriff Dritter zu schützen. Unsere
          Sicherheitsmaßnahmen werden entsprechend der technologischen
          Entwicklung
          fortlaufend verbessert.
          <br/><br/>
          Grundsätzlich sind E-Mails, die ohne zusätzliche Sicherungsmaßnahmen
          (Verschlüsselung) über Internet verschickt werden so wenig vertraulich
          wie eine Postkarte. Sofern Sie mit uns per E-Mail ohne zusätzliche
          Absicherung der Vertraulichkeit kommunizieren, gehen wir davon aus,
          dass
          Sie sich dieses technischen Umstandes bewusst sind und uns gestatten,
          Ihnen ebenfalls per einfacher E-Mail zu antworten.
        </Typography>


        <Typography variant={'h5'} component={'h2'}>
          7. Cookies und Widerspruchsrecht bei Direktwerbung
        </Typography>

        <Typography>
          Als Cookies werden kleine Dateien bezeichnet, die auf Rechnern der
          Nutzer gespeichert werden. Innerhalb der Cookies können verschiedene
          Informationen gespeichert werden. Ein Cookie kann dazu dienen,
          Informationen zu einem Nutzer (wie Browserversion, Interesse des
          Nutzers) während oder auch nach seinem Besuch innerhalb einer Webseite
          zu speichern. Diese Cookies können temporär oder permanent gespeichert
          werden.
          <br/><br/>
          Wir können temporäre und permanente Cookies einsetzen und klären
          hierüber im Rahmen unserer Datenschutzerklärung auf. Falls die
          betroffene Person nicht möchte, dass Cookies auf ihrem Rechner
          gespeichert werden, wird sie gebeten, die entsprechende Option in den
          Systemeinstellungen ihres Browsers zu deaktivieren. Das Verhindern von
          Cookies kann zu Funktionseinschränkungen dieser Webseite führen.
        </Typography>


        <Typography variant={'h5'} component={'h2'}>
          8. Aktualität und Änderung dieser Datenschutzerklärung
        </Typography>

        <Typography>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
          11.04.2022.
          Durch die Weiterentwicklung unserer Website und Angebote darüber oder
          aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben
          kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
          jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website
          unter rh-dt.de/datenschutz von Ihnen abgerufen und ausgedruckt werden.
          Stand: 11.04.2022
        </Typography>
      </Container>
  );
};
export default Datenschutz;