import {LOCATIONS} from './locations';
import {defaultTheme} from './themes/defaultTheme';
import {createCustomTheme} from './customTheme';

export const radiosTheme = createCustomTheme('#1995d9', '#770033');
export const radwmlTheme = createCustomTheme('#1c1e26', '#d1116b')
export const dinkelsbuehlTheme = createCustomTheme('#c79b3a', '#6E6E6E');
export const strahleninstitutTheme = createCustomTheme('#1f3a87', '#EB5F65');
export const braunschweigTheme = createCustomTheme('#CC1717', '#777');
export const helmstedtTheme = createCustomTheme('#CC1717', '#003399');
export const ruhrradiologieGelsenkirchenTheme = createCustomTheme('#871339',
    '#fd9f00');
export const ruhrradiologieEssenTheme = createCustomTheme('#00569d',
    '#fd9f00');
export const ruhrradiologieDortmundTheme = createCustomTheme('#f9b004',
    '#00569d');
export const weselTheme = createCustomTheme('#78B62F', '#008ED1');
export const ebertplatzTheme = createCustomTheme('#818181', '#4c4c4b');
export const rnaTheme = createCustomTheme('#005287', '#F39200');
export const stuttgartTheme = createCustomTheme('#e84127','#505050')

export const getTheme = (location: string | null) => {
  switch (location) {
    case LOCATIONS.STUTTGART:
      return stuttgartTheme
    case LOCATIONS.RADIOS:
      return radiosTheme;
    case LOCATIONS.RADWML:
      return radwmlTheme;
    case LOCATIONS.STRAHLENINSTITUT:
      return strahleninstitutTheme;
    case LOCATIONS.DINKELSBUEHL:
      return dinkelsbuehlTheme;
    case LOCATIONS.BRAUNSCHWEIG:
      return braunschweigTheme;
    case LOCATIONS.HELMSTEDT:
      return helmstedtTheme;
    case LOCATIONS.RUHRRADIOLOGIE_GELSENKIRCHEN:
      return ruhrradiologieGelsenkirchenTheme;
    case LOCATIONS.RUHRRADIOLOGIE_DORTMUND:
    case LOCATIONS.RUHRRADIOLOGIE_WITTEN:
      return ruhrradiologieDortmundTheme;
    case LOCATIONS.RUHRRADIOLOGIE_ESSEN:
      return ruhrradiologieEssenTheme;
    case LOCATIONS.WESEL:
      return weselTheme;
    case LOCATIONS.EBERTPLATZ:
      return ebertplatzTheme;
    case LOCATIONS.RNA:
      return rnaTheme;
    default:
      return defaultTheme;
  }
};
