import {Button, Container, Typography} from '@mui/material';

const Impressum = () => {
  return (
      <Container sx={{my: 3}}>
        <Button href={'/'} sx={{mb: 3}} variant={'contained'}>Zurück zur
          Startseite</Button>
        <Typography variant={'h2'} sx={{mb: 6}}
                    component={'h1'}> Impressum</Typography>
        <Typography sx={{mb: 3}} variant={'body1'}>
          RH Diagnostik & Therapie
          GmbH <br/>
          Pettenkofer Str. 22a | 80336 München</Typography>

        <Typography sx={{mb: 3}} variant={'body1'}>
          Telefon: 089 230890 88 <br/>
          Fax: 089 23 08 90 56 <br/>
          E-Mail: befundportal(at)rh-dt.de <br/>
          Geschäftsführer: Thomas Dollhopf | Oliver Zirwes | Dr. Nikolaus
          Weinberger <br/>
          Sitz: München | USt-Id.Nr.: DE 289647127 <br/>
          Handelsregister: HRB 217075 | Registergericht: Amtsgericht München
        </Typography>

        <Typography sx={{mb: 3}} variant={'body1'}>
          Inhaltlicher Verantwortlicher Art. 18 Abs. 2 MStV:
          Thomas Dollhopf | Oliver Zirwes | Dr. Nikolaus Weinberger
          Anschrift wie oben
        </Typography>

        <Typography sx={{mb: 3}} variant={'body1'}>
          Rechtshinweis / Gewährleistungs-Ausschluss:
          Alle Angaben dieses Internet-Angebots wurden sorgfältig recherchiert
          und
          geprüft. Die zur Verfügung gestellten Informationen, werden laufend
          aktualisiert und unterliegen einer ständigen Kontrolle. Dennoch ist es
          möglich, dass sich Daten trotz sorgfältiger Überprüfung inzwischen
          verändert haben. Eine Garantie für die Vollständigkeit, Richtigkeit
          und
          letzte Aktualität kann daher nicht übernommen werden. Des Weiteren
          behalten sich die RH Diagnostik & Therapie GmbH das Recht vor,
          Änderungen und Ergänzungen der bereitgestellten Informationen
          vorzunehmen.
        </Typography>

        <Typography sx={{mb: 3}} variant={'body1'}>
          Inhalt und Struktur dieser Website unterliegen dem Urheberrecht und
          anderen Gesetzen zum Schutz geistigen Eigentums. Nicht gestattet ist
          die
          Verbreitung oder Veränderung des Inhalts dieser Seiten, des Weiteren
          darf er weder Dritten zugänglich gemacht werden, noch zu kommerziellen
          Zwecken kopiert werden. Dem Urheberrecht unterliegen auch die Bilder,
          die Eigentum der RH Diagnostik & Therapie GmbH sind oder von Dritten
          zur
          Verfügung gestellt wurden.
        </Typography>
        <Typography sx={{mb: 3}} variant={'body1'}>
          Haftungshinweis:
          Die RH Diagnostik & Therapie GmbH übernimmt keinerlei Haftung für
          Ansprüche im Zusammenhang mit diesen Internetseiten.
          Gleiches gilt auch für alle anderen Internetseiten, auf welche von
          dieser Homepage verwiesen werden. Die RH Diagnostik & Therapie GmbH
          haftet nicht für den Inhalt der verlinkten Seiten.
        </Typography>
      </Container>
  );
};
export default Impressum;