import React, {useRef} from 'react';
import './sass/App.scss';
import {ThemeProvider, CssBaseline} from '@mui/material';
import Login from './components/Login';
import FAQ from './components/FAQ';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import PopUp from './components/popups/PopUp';
import {reduxStore} from './reducer/combineReducer';
import {Provider} from 'react-redux';
import UiMessages from './components/ui/uiMessages';
import {getLocation} from './lib/locations';
import {getTheme} from './lib/getTheme';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import NewCode from './components/NewCode';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import Home from './components/pages/Home';
import {defaultTheme} from './lib/themes/defaultTheme';

function App() {
  const location = global.location.pathname.replaceAll('/', '');
  const theme = location == 'rad-wml' ? getTheme(location) : defaultTheme;

  return (
      <Provider store={reduxStore}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline/>
          <Router>
            <Switch>
              <Route path={'/impressum'}>
                <Impressum/>
              </Route>
              <Route path={'/datenschutz'}>
                <Datenschutz/>
              </Route>
              <Route path={'/'}>
                <Home/>
              </Route>
            </Switch>
          </Router>
          <Footer/>
        </ThemeProvider>
      </Provider>
  );
}

export default App;
